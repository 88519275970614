import React from "react";
import ContactForm from "../ContactFrom/ContactForm";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-placeholder"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Adressen</h2>
                      <p>Hasselvägen 18 291 60 Kristianstad</p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p>
                        <a href="mailto:hello@jojostad.se">hello@jojostad.se</a>
                      </p>
                      <p>
                        <a href="mailto:boka@jojostad.se">boka@jojostad.se</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call Now</h2>
                      <p>
                        <a href="tel:+46735036847">+46735036847</a>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {false && (
              <div className="wpo-contact-title">
                <h2>Have Any Question?</h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  content of a page when looking.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe
            title="Hasselvägen 18 291 60 Kristianstad"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17840.347852400348!2d14.1291026!3d56.0179195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465404a0062692eb%3A0xb546c977c6c6ac1d!2sHasselv%C3%A4gen%2018%2C%20291%2060%20Kristianstad!5e0!3m2!1sen!2sse!4v1702457631202!5m2!1sen!2sse"
          ></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
