import pimg from '../images/projects/img-1.jpg'
import pimg2 from '../images/projects/img-2.jpg'
import pimg3 from '../images/projects/img-3.jpg'
import pimg4 from '../images/projects/img-4.jpg'
import pimg5 from '../images/projects/img-5.jpg'



import sSingleimg1 from '../images/project-single/2.jpg'
import sSingleimg2 from '../images/project-single/3.jpg'



const Projects = [
    {
        Id: '1',
        pImg:pimg,
        subTitle:'Muriddban, USA',
        title: 'Carpet',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '2',
        pImg:pimg2,
        subTitle:'Muriddban, USA',
        title: 'Seasonal',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '3',
        pImg:pimg3,
        subTitle:'Muriddban, USA',
        title: 'Express',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '4',
        pImg:pimg4,
        subTitle:'Muriddban, USA',
        title: 'Kitchen',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '5',
        pImg:pimg5,
        subTitle:'Muriddban, USA',
        title: 'Bathroom',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
]
export default Projects;