import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import { Link } from "react-router-dom";
import Benefits from "./benefits";
import ServiceSidebar from "./sidebar";
import Footer from "../../components/footer/Footer.js";
import home from "../../images/home.jpg";
import office from "../../images/office.jpg";
import moving from "../../images/moving.jpg";
import windows from "../../images/windows.jpg";
import bigcleaning from "../../images/bigcleaning.jpg";

const imageMap = {
  1: home,
  2: bigcleaning,
  3: office,
  4: moving,
  5: windows,
};

const ServiceSinglePage = (props) => {
  const { id } = useParams();

  const serviceDetails = Services.find((item) => item.Id === id);

  const ClickHandler = () => {
    // window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} />
      <div
        style={{
          height: 400,
          backgroundImage: `url(${imageMap[id]})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      {false && <PageTitle pageTitle={`${serviceDetails.sTitle}`} />}
      <section className="wpo-service-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="wpo-service-single-wrap">
                {false && (
                  <div className="wpo-service-single-img">
                    <img src={serviceDetails.sImg} alt="" />
                  </div>
                )}
                <div className="wpo-service-single-content">
                  <div className="wpo-service-single-content-des">
                    <h2>{`${serviceDetails.sTitle}  `}</h2>
                    <p>{serviceDetails.description}</p>

                    <ServiceDetails service={serviceDetails} />

                    <h2>Prisförslag</h2>
                    <table style={{ width: "100%", marginBottom: 24 }}>
                      <thead>
                        <tr>
                          {serviceDetails.price[0].map((v) => (
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: 8,
                              }}
                            >
                              {v}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {serviceDetails.price.slice(1).map((tr) => (
                          <tr>
                            {tr.map((v) => (
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: 8,
                                }}
                              >
                                {v}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {serviceDetails.notes.map((note) => (
                      <p>{note}</p>
                    ))}
                    {false && (
                      <div className="wpo-service-single-sub-img">
                        <ul>
                          <li>
                            <img src={serviceDetails.ssImg1} alt="" />
                          </li>
                          <li>
                            <img src={serviceDetails.ssImg2} alt="" />
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                {false && (
                  <div className="wpo-related-section">
                    <h2>Related Service</h2>
                    <div className="row">
                      {Services.slice(0, 3).map((service, Sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
                          <div className="wpo-related-item">
                            <div className="wpo-related-icon">
                              <div className="icon">
                                <i>
                                  <img src={service.sIcon} alt="" />
                                </i>
                              </div>
                            </div>
                            <div className="wpo-related-text">
                              <h3>
                                <Link
                                  onClick={ClickHandler}
                                  to={`/service-single/${service.Id}`}
                                >
                                  {service.sTitle}
                                </Link>
                              </h3>
                              <p>{service.description.slice(41)}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {false && <Benefits />}
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;

function ServiceDetails({ service }) {
  return (
    <div>
      {service.items.map((item, index) => (
        <div key={index}>
          <h3>{item.title}</h3>
          {item.bullets.length === 1 ? (
            <p>{item.bullets[0]}</p>
          ) : (
            <ul style={{ marginBottom: 24 }}>
              {item.bullets.map((bullet, index) => (
                <li key={index}>{bullet}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}
