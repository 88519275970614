import React from "react";
import { Link } from "react-router-dom";

const Hero3 = () => {
  return (
    <section className="wpo-hero-section-2">
      <div className="container">
        <div className="row">
          <div className="col col-xs-5 col-lg-5 col-12">
            <div className="wpo-hero-section-text">
              <div className="wpo-hero-title">
                <h2 style={{ fontSize: 90 }}>JoJo Städ och Service</h2>
              </div>
              <div className="wpo-hero-subtitle">
                <p>Kvalitetet är vårt motto och Nöjda kunder vårt resultat</p>
                <p>
                  Våra tjänster utförs vanligtvis av våra professionella städare
                  och kan anpassas efter kundens behov och önskemål.
                </p>
              </div>
              <div className="btns">
                <a href="#boka-tid">
                  <button type="submit" className="theme-btn">
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>{" "}
                    Boka Nu
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-vec">
        <div className="right-img"></div>
      </div>
    </section>
  );
};

export default Hero3;
