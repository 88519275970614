import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import PartnerSection from "../../components/PartnerSection/PartnerSection";
import Footer from "../../components/footer/Footer.js";
import Scrollbar from "../../components/scrollbar/scrollbar";

const ServicePage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Service"} pagesub={"Service"} />
      <ServiceSection />
      <PartnerSection tNone={"partners-section-s2"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
