import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero3 from "../../components/hero3/hero3";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSectionS2 from "../../components/ServiceSectionS2/ServiceSectionS2";
import WorkSection from "../../components/WorkSection/WorkSection";
import Appointment from "../../components/Appointment/Appointment";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import TeamSection from "../../components/TeamSection/TeamSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import BlogSection from "../../components/BlogSection/BlogSection.js";
import PartnerSection from "../../components/PartnerSection/PartnerSection";
import Footer from "../../components/footer/Footer.js";
import Pricing from "../../components/Pricing/Pricing.js";

const Homepage3 = () => {
  return (
    <Fragment>
      <Navbar topbarNone={"topbar-none"} />
      <Hero3 />
      <ServiceSectionS2 />
      {false && <WorkSection />}

      {false && <Pricing />}

      <Appointment />
      {false && <ProjectSection />}
      {false && <TeamSection />}
      {false && <Testimonial />}
      {false && <BlogSection />}
      <PartnerSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default Homepage3;
