import React from "react";
import Services from "../../api/service";
import { Link } from "react-router-dom";

const ServiceSidebar = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="col-lg-4 col-md-8">
      <div className="wpo-single-sidebar">
        <div className="wpo-service-widget widget">
          <h2>Tjänster</h2>
          <ul>
            {Services.slice(0, 5).map((service, Sitem) => (
              <li key={Sitem}>
                <Link
                  onClick={ClickHandler}
                  to={`/service-single/${service.Id}`}
                >
                  {service.sTitle}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="wpo-contact-widget widget">
          <h2>Hur kan vi hjälpa dig!</h2>
          <p>
            om du har frågor om en specifik tjänst , tvek inte att kontakta oss
          </p>
          <Link to="/contact">Kontakt oss</Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceSidebar;
