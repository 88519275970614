import simg from "../images/service/1.jpg";
import simg2 from "../images/service/2.jpg";
import simg3 from "../images/service/3.jpg";
import simg4 from "../images/service/4.jpg";
import simg5 from "../images/service/5.jpg";
import simg6 from "../images/service/6.jpg";
import simg7 from "../images/service/1.jpg";
import simg8 from "../images/service/7.jpg";
import simg9 from "../images/service/8.jpg";
import simg10 from "../images/service/9.jpg";
import simg11 from "../images/service/5.jpg";
import simg12 from "../images/service/10.jpg";

import sIcon1 from "../images/icon/carpet.svg";
import sIcon2 from "../images/icon/season.svg";
import sIcon3 from "../images/icon/express-delivery.svg";
import sIcon4 from "../images/icon/cutlery.svg";
import sIcon5 from "../images/icon/shower.svg";
import sIcon6 from "../images/icon/1.svg";
import sIcon7 from "../images/icon/2.svg";
import sIcon8 from "../images/icon/3.svg";
import sIcon9 from "../images/icon/4.svg";
import sIcon10 from "../images/icon/5.svg";
import sIcon11 from "../images/icon/5.svg";
import sIcon12 from "../images/icon/5.svg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg,
    sIcon: sIcon8,
    sTitle: "Hemstädning",
    description:
      "Hemstädning är en tjänst som syftar till att skapa och upprätthålla en ren och välorganiserad livsmiljö i ert hem. Denna typ av tjänst utförs vanligtvis av våra  professionella städare och kan anpassas efter kundens behov och önskemål.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [
      {
        title: "Dammsugning",
        bullets: [
          "Detta inkluderar dammsugning av alla rum, inklusive golv, mattor, och möbler.",
        ],
      },

      {
        title: "Damning",
        bullets: [
          "Avlägsnande av damm från ytor som möbler, hyllor, och andra ytor.",
        ],
      },

      {
        title: "Moppning",
        bullets: [
          "Rengöring av hårda golv som trä, klinker eller laminat genom att använda en mopp.",
        ],
      },

      {
        title: "Kök",
        bullets: [
          "Rengöring av köksytor, diskbänkar, spisar, ugnar och andra köksapparater. Diskning och rengöring av diskbänk och eventuella ytor.",
        ],
      },

      {
        title: "Badrum",
        bullets: [
          "Rengöring av toaletter, handfat, badkar eller duschar. Rengöring av speglar och eventuella hyllor.",
        ],
      },

      {
        title: "Sängkläder och textilvård",
        bullets: [
          "Byta sängkläder och eventuell tvätt av använda handdukar. Detta kan göras regelbundet eller enligt behov.",
        ],
      },

      {
        title: "Organisering",
        bullets: [
          "Sortering och organisering av föremål och saker i hemmet. Detta kan inkludera att sätta tillbaka saker på sina platser och rensa upp oordning.",
        ],
      },

      {
        title: "Sopborttagning",
        bullets: [
          "Kasta skräp och sortera återvinningsmaterial. Detta inkluderar att tömma soptunnor och återvinna efter behov.",
        ],
      },
    ],
    price: [
      [
        "Hur ofta i veckan ",
        "Priset med RUT avdrag ",
        "Priset utan RUT avdrag",
      ],
      ["2 timmar varje vecka ", "2.522 kr/mån", "5.044 kr/mån"],
      ["2 timmar varannan vecka", "1.284 kr/mån", "2.568 kr/mån"],
      ["2 timmar var fjärde vecka ", "618 kr/mån", "1.236 kr/mån"],
    ],
    notes: [
      "Priser med RUT indikerar det slutliga priset efter tillämpning av skattereduktion. Denna skattereduktion innebär en betydande förmån för kunder genom antingen rutavdrag, där arbetskostnaden halveras, eller rotavdrag, där en 30% avdrag tillämpas på arbetskostnaden",
      "Vår hemstädningstjänst (inget abonnemang krävs) är prissatt till 298 kr per timme. Priserna är exklusive RUT och inklusive moms. Utan avdrag för RUT är vårt timpris 596 kr. Vi tar ut en avbokningsavgift på 150 kr per besök, vilket inkluderar transport-, städ- och administrationsavgifter.",
    ],
  },
  {
    Id: "2",
    sImg: simg2,
    sIcon: sIcon6,
    sTitle: "Storstädning",
    description:
      "Det är en djupare form av rengöring som syftar till att rengöra områden och ytor som kanske inte får lika mycket uppmärksamhet under den dagliga eller veckovisa städningen. Storstädning utförs vanligtvis mindre frekvent, kanske en gång per månad, varannan månad eller vid behov. Här är några aspekter som kan ingå i en större städning:",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [
      {
        title: "Grundlig dammsugning och dammtorkning",
        bullets: [
          "Intensiv dammsugning av hela hemmet, inklusive svåråtkomliga områden som bakom och under möbler. Dammtorkning av ytor och möbler.",
        ],
      },
      {
        title: "Rengöring av gardiner och persienner",
        bullets: [
          "Tvätt eller rengöring av gardiner och persienner för att ta bort damm och allergener.",
        ],
      },
      {
        title: "Rengöring av tak och väggar",
        bullets: [
          "Avlägsnande av spindelväv eller damm från takhörn och väggar.",
        ],
      },
      {
        title: "Rengöring av möbler",
        bullets: [
          "Noggrann rengöring av möbler, inklusive att ta bort kuddar och rengöra under dem.",
        ],
      },
      {
        title: "Rengöring av vitvaror",
        bullets: [
          "Inspektions- och rengöringsarbete för vitvaror som ugn, spis, mikrovågsugn, kylskåp och diskmaskin.",
        ],
      },
      {
        title: "Djuprengöring av golv",
        bullets: [
          "Användning av specialrengöringsprodukter eller maskiner för att rengöra och polera golvytor.",
        ],
      },
      {
        title: "Organisering och avstädning",
        bullets: [
          "Genomgående organisering och avstädning av olika områden och förvaringsutrymmen.",
        ],
      },
      {
        title: "Rengöring av badrum",
        bullets: [
          "Djuprengöring av alla ytor, inklusive fogar, badkar, duschkabin och toalett.",
        ],
      },
      {
        title: "Byte av sängkläder och textilier",
        bullets: ["Tvätt och byte av sängkläder, kuddar och andra textilier."],
      },
    ],
    price: [
      ["Yta", "priset med RUT", "Priset utan RUT"],
      ["0 - 60 m2", "4.834:-", "2.416:-"],
      ["61 - 100 m2", "7.980:-", "3.889:-"],
      ["101 - 150 m2", "9.598:-", "4.798:-"],
      ["151 - 200 m2", "12.424:-", "6.211:-"],
      ["201 - 250 m2", "13.868:-", "6.933:-"],
    ],
    notes: [
      "Priser med RUT indikerar det slutliga priset efter tillämpning av skattereduktion. Denna skattereduktion innebär en betydande förmån för kunder genom antingen rutavdrag, där arbetskostnaden halveras, eller rotavdrag, där en 30% avdrag tillämpas på arbetskostnaden.",
      "En inställningskostnad på 398 kr per tillfälle tillkommer för att täcka de initiala arrangemangen och förberedelserna. Denna kostnad är en engångsavgift och faktureras oavsett omfattningen av det aktuella tillfället eller evenemanget.",
    ],
  },
  {
    Id: "3",
    sImg: simg3,
    sIcon: sIcon10,
    sTitle: "Kontorsstädning",
    description:
      "Kontorsstädning är en professionell städtjänst som syftar till att skapa en ren och hälsosam arbetsmiljö inom kontorslokaler. Denna tjänst utförs av våra specialiserade städare som är utbildade för att hantera de unika utmaningar och behov som är förknippade med kontorsmiljön.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [
      {
        title: "Daglig städning",
        bullets: [
          "Tömning av papperskorgar.",
          "Dammsugning och moppning av golv.",
          "Torkning av ytor, som skrivbord, bord, och datorutrustning.",
          "Rengöring av dörrhandtag och ljusknappar.",
        ],
      },
      {
        title: "Kök och matsal",
        bullets: [
          "Rengöring av köksytor, inklusive diskbänkar och spisar.",
          "Torkning av köksbord och stolar.",
          "Diskning och rengöring av gemensamma köksutrymmen.",
          "Tömning av kaffemaskinens sopbehållare.",
        ],
      },
      {
        title: "Toaletter",
        bullets: [
          "Rengöring av toaletter, handfat och speglar.",
          "Påfyllning av toalettpapper, tvål och pappershanddukar.",
          "Moppning av golvet.",
        ],
      },
      {
        title: "Dammning och rengöring av möbler",
        bullets: [
          "Avlägsnande av damm från möbler och hyllor.",
          "Rengöring av bord och stolar.",
        ],
      },

      {
        title: "Sophantering",
        bullets: [
          "Tömning av centrala sopbehållare. Återvinning av papper, plast, och andra återvinningsbara material.",
        ],
      },
      {
        title: "Organisering",
        bullets: [
          "Organisering av gemensamma utrymmen och arbetsstationer. Återställning av möbler och föremål till sina ursprungliga platser.",
        ],
      },
    ],
    price: [
      ["Hur ofta i veckan", "Priset utan RUT avdrag", "Priset med RUT avdrag"],
      ["2 timmar varje dag", "12.000 kr/mån", "6.000 kr/mån"],
      ["2 timmar varje vecka ", "4.400 kr/mån", "2.200 kr/mån"],
      ["2 timmar varannan vecka ", "2.200 kr/mån", "1.100 kr/mån"],
      ["2 timmar var fjärde vecka ", "1.100 kr/mån", "550 kr/mån"],
    ],
    notes: [
      "Priser med RUT indikerar det slutliga priset efter tillämpning av skattereduktion. Denna skattereduktion innebär en betydande förmån för kunder genom antingen rutavdrag, där arbetskostnaden halveras, eller rotavdrag, där en 30% avdrag tillämpas på arbetskostnaden.",
    ],
  },
  {
    Id: "4",
    sImg: simg4,
    sIcon: sIcon3,
    sTitle: "Flyttstädning",
    description:
      "Flyttstädning, inkluderar vanligtvis en omfattande rengöring av hela bostaden för att lämna den i ett välstädat skick för nästa hyresgäst. Vad som exakt ingår kan variera beroende på överenskommelsen mellan städfirman och hyresgästen eller fastighetsägaren, men nedan är en generell lista över vad som ofta förväntas ingå i flyttstädningen",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [
      {
        title: "Kök",
        bullets: [
          "Rengöring av ugn, spis, och mikrovågsugn.",
          "Avfrostning och rengöring av frysen och kylskåpet.",
          "Rengöring av diskbänk och avlägsnande av eventuella kalkavlagringar.",
          "Rengöring av köksfläkten och fettfiltret.",
          "Rengöring av köksskåp in- och utvändigt.",
        ],
      },

      {
        title: "Badrum",
        bullets: [
          "Rengöring av toalett, badkar och/eller dusch.",
          "Avkalkning av kranar och duschar.",
          "Rengöring av handfat och speglar.",
          "Rengöring av badrumsskåp in- och utvändigt.",
        ],
      },

      {
        title: "Allmänna utrymmen",
        bullets: [
          "Dammtorkning av alla ytor.",
          "Dammsugning och våttorkning av alla golv.",
          "Avtorkning av dörrar och dörrkarmar.",
          "Borttagning av spindelnät.",
        ],
      },

      {
        title: "Övrigt",
        bullets: [
          "Rengöring av alla vitvaror som tvättmaskin och torktumlare.",
          "Dammsugning och rengöring av garderober och andra förvaringsutrymmen.",
          "Borttagning av eventuella märken på väggarna.",
          "Avlägsnande av skräp och sopor.",
        ],
      },
    ],
    price: [
      ["Yta", "priset utan RUT", "Priset med RUT"],
      ["0 - 59 m2", "116:-/m2", "58:-/m2"],
      ["60 - 120 m2", "102:-/m2", "51:-/m2"],
      ["121 - 200 m2", "92:-/m2", "46:-/m2"],
      ["201 - 300 m2", "80:-/m2", "40:-/m2"],
    ],
    notes: [
      "Priser med RUT indikerar det slutliga priset efter tillämpning av skattereduktion. Denna skattereduktion innebär en betydande förmån för kunder genom antingen rutavdrag, där arbetskostnaden halveras, eller rotavdrag, där en 30% avdrag tillämpas på arbetskostnaden.",
      "En inställningskostnad på 498 kr per tillfälle tillkommer för att täcka de initiala arrangemangen och förberedelserna. Denna kostnad är en engångsavgift och faktureras oavsett omfattningen av det aktuella tillfället eller evenemanget.",
    ],
  },

  {
    Id: "5",
    sImg: simg5,
    sIcon: sIcon7,
    sTitle: "Fönsterputs",
    description: `
      JoJo Städ och Service erbjuder trygg och professionell fönsterputs för samtliga fönsterytor. Vår verksamhet utgår från i Kristianstad och sträcker sig över hela området, vilket innebär att vi erbjuder våra fönsterputsningstjänster till både privata hem och företag.
Oavsett om du behöver fönsterputsning vid specifika tillfällen eller om du föredrar en regelbunden service, är vi här för att möta dina behov. Du kan boka oss för enstaka tillfällen när behovet uppstår, eller så kan du dra nytta av vårt abonnemang för att säkerställa att dina fönster förblir rena och klara året runt.
Med vårt fokus på trygghet, professionalism och flexibilitet strävar vi efter att göra fönsterputsningen så bekväm och tillgänglig som möjligt för dig som kund. Boka oss och njut av klara och rena fönster som förbättrar både utseendet och ljusinsläppet i ditt hem eller företag.

      `,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [
      {
        title: "Vad det som ingår i Fönsterputs",
        bullets: [
          "Utvändig putsning",
          "Borttagning av svåra fläckar på utsidan",
          "Torkning av utvändiga karmar och fönsterbräden",
          "Invändig putsning, då ingår borttagning av svåra fläckar på insidan",
          "Putsning mellan glas om fönstren är delningsbara",
          "Torkning av invändiga karmar och fönsterbräden",
        ],
      },
    ],
    price: [
      ["Hur ofta per år ", "Priset med RUT avdrag", "Priset utan RUT avdrag"],
      [
        "4 tillfällen per år upp till 20 fönster på bottenplan, endast utvändigt",
        "199 kr per timme",
        "398 kr per timme",
      ],
      [
        "3 tillfällen per år upp till 20 fönster på bottenplan, endast utvändigt",
        "248 kr per timme",
        "496 kr per timme",
      ],
      [
        "2 tillfällen per år upp till 20 fönster på bottenplan, endast utvändigt",
        "297 kr per timme ",
        "594 kr per timme ",
      ],
    ],
    notes: [
      "Vårt ordinarie timpris för enstaka tillfällen är 598 kr. Efter att ha utnyttjat rutavdraget blir det reducerade timpriset 299 kr. Rutavdraget är en förmånlig möjlighet för våra kunder att dra nytta av en halverad kostnad per timme, vilket gör våra tjänster mer tillgängliga och prisvärda.",
    ],
  },
  /*
  {
    Id: "6",
    sImg: simg6,
    sIcon: sIcon6,
    sTitle: "Windows",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [],

  },
  {
    Id: "7",
    sImg: simg7,
    sIcon: sIcon7,
    sTitle: "Carpet",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    items: [],

  },
  {
    Id: "8",
    sImg: simg8,
    sIcon: sIcon8,
    sTitle: "Glass",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "9",
    sImg: simg9,
    sIcon: sIcon9,
    sTitle: "Table",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "10",
    sImg: simg10,
    sIcon: sIcon10,
    sTitle: "Computer",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "11",
    sImg: simg11,
    sIcon: sIcon11,
    sTitle: "Bathroom",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "12",
    sImg: simg12,
    sIcon: sIcon12,
    sTitle: "Chair",
    description:
      "We are certified company. We provide best cleaning services for you and your company Home services and your Car.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },*/
];
export default Services;
