import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar.js";
import PageTitle from "../../components/pagetitle/PageTitle.js";
import Scrollbar from "../../components/scrollbar/scrollbar.js";
import FAQ from "./faq.js";
import Footer from "../../components/footer/Footer.js";
import tImg1 from "../../images/terms/terms.jpg";
import tImg2 from "../../images/terms/1.jpg";
import tImg3 from "../../images/terms/2.jpg";

const PrivacyPage = (props) => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"integritetspolicy"} pagesub={""} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-text">
                  <h2>Integritetspolicy</h2>
                  <p>
                    JoJo Städ och Service sätter högt värde på din personliga
                    integritet och vi strävar ständigt efter att säkerställa en
                    optimal skyddsnivå för dina personuppgifter, i full
                    överensstämmelse med dataskyddsförordningen (GDPR). Genom
                    denna integritetspolicy vill vi tydligt och transparent
                    informera dig om hur JoJo Städ och Service behandlar och
                    hanterar dina personuppgifter. Vår målsättning är att skapa
                    en trygg och förtroendefull relation med dig som kund genom
                    att säkerställa att dina personuppgifter behandlas med
                    respekt och ansvar.
                  </p>

                  <p>
                    JoJo Städ och Service samlar in uppgifter från dig när du
                    fyller i ett formulär på vår webbsida, registrerar dig som
                    kund genom vår kundtjänst eller på annat sätt interagerar
                    med oss.
                  </p>
                  <ul>
                    <li>Namn</li>
                    <li>Adress</li>
                    <li>Telefonnummer</li>
                    <li>E-postadress</li>
                    <li>Bostadsinformation:</li>
                    <li>Larmkoder</li>
                    <li>Dörrkoder</li>
                    <li>Nycklar</li>
                  </ul>
                </div>
                {false && <FAQ />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PrivacyPage;
