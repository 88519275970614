import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import FAQ from "./faq";
import Footer from "../../components/footer/Footer.js";
import tImg1 from "../../images/terms/terms.jpg";
import tImg2 from "../../images/terms/1.jpg";
import tImg3 from "../../images/terms/2.jpg";

const TermPage = (props) => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Allmänna villkor"} pagesub={""} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-text">
                  <h2>Allmänna villkor</h2>
                  <p>
                    Dessa villkor gäller för samtliga avtalade tjänster om inte
                    annat tydligt anges i avtalet för hushållsnära tjänster.
                    Begreppet "Uppdraget" refererar till de tjänster som JoJo
                    Städ och Service utför åt "Kunden" mot överenskommen
                    ersättning, enligt avtal för hushållsnära tjänster.
                    Definitionen av "huvudavtalet" inkluderar den överenskomna
                    uppdragsbeskrivningen mellan parterna. För att komplettera
                    och tydliggöra huvudavtalet tillämpas även dessa allmänna
                    villkor.
                  </p>

                  <h3>1. Uppdraget</h3>
                  <p>
                    JoJo Städ och Service förbinder sig att utföra överenskomna
                    tjänster på ett professionellt och för branschen korrekt
                    sätt. JoJo Städ och Service använder sig av professionella
                    och utbildade medarbetare.
                  </p>
                  <h3>2. Beställd tid</h3>
                  <p>Detta framgår av avtal om hushållsnära tjänster.</p>
                  <h3>3. Bokning</h3>
                  <p>
                    Med bokning avses per telefon eller e-post av Kunden godkänt
                    förslag på tid för tjänstens utförande. Bokning är bindande
                    och kan endast ändras som beskrivet i punkt 4. Städade Hem
                    förbehåller sig rätten att ställa in bokat tillfälle. Detta
                    meddelas Kunden före uppdragets starttid.
                  </p>

                  <h3>4. Avbokning och ombokning</h3>
                  <p>
                    Städtillfällen kan avbokas av Kunden via mejl eller
                    telefonsamtal till Städade Hem. Vid avbokning 1-5
                    arbetsdagar innan städtillfället debiteras 50% av det
                    planerade städtillfället. Vid senare avbokning än 24 timmar
                    debiteras Kunden 100% av det planerade
                    städtillfället.Sjukdom påverkar inte reglerna för avbokning
                    och ombokning. Du som Kund har rätt att kostnadsfritt avboka
                    din städning 6 arbetsdagar eller tidigare innan ditt
                    städtillfälle.
                  </p>
                  <h3>5. Ombokning av städtillfälle</h3>
                  <p>
                    Istället för att avboka din städning kan du som Kund välja
                    att omboka din städning 1-14 dagar framåt om du är sjuk
                    eller av annan anledning måste omboka. Om Kunden vill avboka
                    ett redan ombokat städtillfälle så kommer detta debiteras
                    enligt avbokningsvillkoren. Det går inte att omboka ett
                    redan ombokat städtillfälle igen.
                  </p>
                  <h3>6. Betalningsvillkor</h3>
                  <p>
                    Fakturering sker den 15:e varje månad. Betalning skall ske
                    senast inom 15 dagar från fakturadatum eller om det är till
                    fördel för Kunden det förfallodatum som anges på fakturan.
                    Vid betalning ange kontonummer som referens. Det är Kundens
                    ansvar att kontrollera så att tak för skattereduktion ej
                    överstigits. Om Skatteverket i någon omfattning nekar
                    utbetalning eller om JoJo Städ och Service blir
                    återbetalningsskyldig för utbetalt belopp har JoJo Städ och
                    Service i efterhand rätt att kräva Kunden på motsvarande
                    belopp. Det finns Swish som betalningssätt i alternativet.
                  </p>
                  <h3>7. Försäkring</h3>
                  <p>
                    JoJo Städ och Service innehar ansvarsförsäkring som säkerhet
                    för fullgörandet av eventuell skadeståndsskyldighet enligt
                    dessa allmänna villkor. JoJo Städ och Service har
                    försäkringar som täcker både person- och sakskada vid varje
                    skadetillfälle.
                  </p>
                  <h3>8. Ansvar</h3>
                  <p>
                    JoJo Städ och Service är skyldig att utge ersättning för
                    skador som tillfogats Kunden på grund av Städade Hems del.
                    Personskador regleras i enlighet med Städade Hems
                    försäkring. Kunden skall så långt möjligt vidta åtgärder för
                    att begränsa skadans omfattning. JoJo Städ och Service kan
                    inte göras ansvarig för maskinhaveri eller andra skador som
                    orsakas av normalt slitage på Kundens maskiner eller
                    utrustning. Eventuella skador eller annan åverkan på sådant
                    som användes av JoJo Städ och Serviceutförare, som inte
                    orsakas av slarv eller annan skadegörelse av denne, skall
                    ersättas av Kunden. Om Kunden kräver ersättning för skadad
                    maskin eller utrustning skall det skadade objektet lämnas
                    över till JoJo Städ och Service.
                  </p>
                </div>
                {false && <FAQ />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TermPage;
