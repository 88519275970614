import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import cimg from "../../images/contact.png";
import emailjs from "@emailjs/browser";

const Appointment = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const formRef = React.useRef();
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    //

    console.log({ forms });
    if (validator.allValid()) {
      validator.hideMessages();

      emailjs
        .sendForm("service_jojostad", "template_kuzxv6l", formRef.current, {
          publicKey: "wdFmzuv6SWqDL7Nzt",
        })
        .then(
          () => {
            console.log("SUCCESS!");
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );

      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <section id="boka-tid" className="wpo-contact-section section-padding">
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title-s2">
                  <h2>Boka tid</h2>
                </div>
                <form
                  ref={formRef}
                  onSubmit={(e) => submitHandler(e)}
                  className="contact-validation-active"
                >
                  <div className="row">
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.name}
                          type="text"
                          name="name"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Namn"
                        />
                      </div>
                      {validator.message(
                        "name",
                        forms.name,
                        "required|alpha_space"
                      )}
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.email}
                          type="email"
                          name="email"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="E-post"
                        />
                        {validator.message(
                          "email",
                          forms.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.phone}
                          type="phone"
                          name="phone"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Telefonnummer"
                        />
                        {validator.message("phone", forms.phone, "")}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <select
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.subject}
                          type="text"
                          name="subject"
                        >
                          <option>Välja din tjänst</option>
                          <option>Hemstädning</option>
                          <option>Storstädning</option>
                          <option>Kontorsstädning</option>
                          <option>Flyttstädning</option>
                          <option>Fönsterputs</option>
                          <option>Annat</option>
                        </select>
                        {validator.message("subject", forms.subject, "")}
                      </div>
                    </div>

                    <div className="col fullwidth col-lg-12 ">
                      <input
                        className="form-control"
                        value={forms.address}
                        type="text"
                        name="address"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        placeholder="Postadress"
                      />
                      {validator.message("address", forms.name, "")}
                    </div>

                    <div className="col fullwidth col-lg-12 ">
                      <textarea
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Meddelande"
                      ></textarea>
                      {validator.message("message", forms.message, "required")}
                    </div>
                  </div>

                  <div className="submit-area">
                    <button type="submit" className="theme-btn">
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>{" "}
                      Skicka
                    </button>
                  </div>
                </form>
                <div className="border-style"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wpo-contact-img">
                <img src={cimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
