import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/footer-logo.svg";
import Services from "../../api/service";
import { Button } from "@mui/material";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img style={{ height: 80 }} src={Logo} alt="blog" />
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                {false && (
                  <div className="widget-title">
                    <h3>Quick Link</h3>
                  </div>
                )}
                {false && (
                  <ul>
                    <li>
                      <Link to="/about">About Us </Link>
                    </li>
                    <li>
                      <Link to="/service-s2">Service</Link>
                    </li>
                    <li>
                      <Link to="/appointment">Appointment</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us </Link>
                    </li>
                    <li>
                      <Link to="/project">Projects</Link>
                    </li>
                  </ul>
                )}
                <ul style={{ marginTop: 24 }}>
                  <li>
                    <Link to="/terms">Allmänna villkor</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                {false && (
                  <div className="widget-title">
                    <h3>Newsletter</h3>
                  </div>
                )}

                <ul style={{ marginTop: 24 }}>
                  <li>
                    <Link to="/privacy">Integritetspolicy</Link>
                  </li>
                </ul>

                {false && (
                  <form onSubmit={SubmitHandler}>
                    <input
                      type="email"
                      placeholder="support@gmail.com"
                      required
                    />
                    <button type="submit">
                      Send Now <i className="ti-arrow-right"></i>
                    </button>
                  </form>
                )}
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                {false && (
                  <div className="widget-title">
                    <h3>Services</h3>
                  </div>
                )}

                <ul style={{ marginTop: 24 }}>
                  <li>
                    <Link to="/contact">Kontakt oss</Link>
                  </li>
                </ul>

                {false && (
                  <ul>
                    {Services.slice(0, 6).map((service, srv) => (
                      <li key={srv}>
                        <Link
                          onClick={ClickHandler}
                          to={`/service-single/${service.Id}`}
                        >
                          {service.sTitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ margin: 16 }}
            href="https://www.facebook.com/profile.php?id=61556124219918&mibextid=2JQ9oc"
          >
            <i
              className="fa fa-facebook"
              style={{ fontSize: 40, color: "#fff" }}
            ></i>
          </Button>
          <Button
            style={{ margin: 16 }}
            href="https://twitter.com/@jojostadservice"
          >
            <i
              className="fa fa-twitter"
              style={{ fontSize: 40, color: "#fff" }}
            ></i>
          </Button>
          <Button
            style={{ margin: 16 }}
            href="https://www.instagram.com/jojo.stad_service?igsh=MW1tcWs2Ynkwamdnag%3D%3D&utm_source=qr"
          >
            <i
              className="fa fa-instagram"
              style={{ fontSize: 40, color: "#fff" }}
            ></i>
          </Button>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                Copyright &copy; 2023 | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
