import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.svg";

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={props.topbarNone}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                {true && (
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/">
                    <img style={{ height: 80 }} src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link>Tjänster</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/service-single/1">
                            Hemstädning
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/service-single/2">
                            Storstädning
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/service-single/3">
                            Kontorsstädning
                          </Link>
                        </li>

                        <li>
                          <Link onClick={ClickHandler} to="/service-single/4">
                            Flyttstädning
                          </Link>
                        </li>

                        <li>
                          <Link onClick={ClickHandler} to="/service-single/5">
                            Fönsterputs
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Kontakt oss
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-2">
                <div className="header-right">
                  <div className="close-form">
                    <a
                      onClick={ClickHandler}
                      className="theme-btn"
                      href="tel:+46735036847"
                    >
                      {" "}
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>Boka nu</span> 0735036847
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
